
import draw from "./draw.mp3";
import mouseClick from "./mouse click.mp3";
import pickUp from "./pick up.mp3";
import putDownCancel from "./put down cancel.mp3";
import putDownMove from "./put down move.mp3";
import putDownPromote from "./put down promote.mp3";
import putDownTake from "./put down take.mp3";
import startGame from "./start game.mp3";
import youLose from "./you lose.mp3";
import youWin from "./you win.mp3";
import yourMove from "./your move.mp3";

const audio = {
  draw,
  mouseClick,
  pickUp,
  putDownCancel,
  putDownMove,
  putDownPromote,
  putDownTake,
  startGame,
  youLose,
  youWin,
  yourMove,
};

export default audio;