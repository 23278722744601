const positionIncentives = {
  "wPawn": [
    [0, 34, 6, 1, 5, 0],
    [0, 43, 8, 3, 3, 5, 0],
    [0, 50, 10, 5, 0, -5, 10, 0],
    [0, 44, 28, 14, 5, -4, -5, 9, 0],
    [0, 39, 39, 28, 24, 20, 7, -9, -16, 0],
    [0, 35, 42, 30, 26, 23, 16, 2, -12, -13, 0],
    [0, 39, 39, 28, 24, 21, 7, -9, -16, 0],
    [0, 44, 28, 14, 5, -4, -5, 9, 0],
    [0, 50, 10, 5, 0, -5, 10, 0],
    [0, 43, 8, 3, 3, 5, 0],
    [0, 34, 6, 1, 5, 0]
  ],
  "bPawn": [
      [
          0,
          5,
          1,
          6,
          34,
          0
      ],
      [
          0,
          5,
          3,
          3,
          8,
          43,
          0
      ],
      [
          0,
          10,
          -5,
          0,
          5,
          10,
          50,
          0
      ],
      [
          0,
          9,
          -5,
          -4,
          5,
          14,
          28,
          44,
          0
      ],
      [
          0,
          -16,
          -9,
          7,
          20,
          24,
          28,
          39,
          39,
          0
      ],
      [
          0,
          -13,
          -12,
          2,
          16,
          23,
          26,
          30,
          42,
          35,
          0
      ],
      [
          0,
          -16,
          -9,
          7,
          21,
          24,
          28,
          39,
          39,
          0
      ],
      [
          0,
          9,
          -5,
          -4,
          5,
          14,
          28,
          44,
          0
      ],
      [
          0,
          10,
          -5,
          0,
          5,
          10,
          50,
          0
      ],
      [
          0,
          5,
          3,
          3,
          8,
          43,
          0
      ],
      [
          0,
          5,
          1,
          6,
          34,
          0
      ]
  ],
  "wKing": [
    [-30, -30, -30, -18, 8, 20],
    [-30, -30, -30, -25, -13, 15, 20],
    [-40, -40, -40, -40, -30, -20, 20, 30],
    [-40, -40, -40, -40, -35, -26, -15, 1, 10],
    [-50, -50, -50, -50, -49, -41, -27, -11, 0, 0],
    [-50, -50, -50, -50, -50, -45, -36, -22, -8, 0, 0],
    [-50, -50, -50, -50, -49, -41, -27, -11, 0, 0],
    [-40, -40, -40, -40, -35, -26, -15, 1, 10],
    [-40, -40, -40, -40, -30, -20, 20, 30],
    [-30, -30, -30, -25, -13, 15, 20],
    [-30, -30, -30, -18, 8, 20]
  ],
  "bKing": [
      [
          20,
          8,
          -18,
          -30,
          -30,
          -30
      ],
      [
          20,
          15,
          -13,
          -25,
          -30,
          -30,
          -30
      ],
      [
          30,
          20,
          -20,
          -30,
          -40,
          -40,
          -40,
          -40
      ],
      [
          10,
          1,
          -15,
          -26,
          -35,
          -40,
          -40,
          -40,
          -40
      ],
      [
          0,
          0,
          -11,
          -27,
          -41,
          -49,
          -50,
          -50,
          -50,
          -50
      ],
      [
          0,
          0,
          -8,
          -22,
          -36,
          -45,
          -50,
          -50,
          -50,
          -50,
          -50
      ],
      [
          0,
          0,
          -11,
          -27,
          -41,
          -49,
          -50,
          -50,
          -50,
          -50
      ],
      [
          10,
          1,
          -15,
          -26,
          -35,
          -40,
          -40,
          -40,
          -40
      ],
      [
          30,
          20,
          -20,
          -30,
          -40,
          -40,
          -40,
          -40
      ],
      [
          20,
          15,
          -13,
          -25,
          -30,
          -30,
          -30
      ],
      [
          20,
          8,
          -18,
          -30,
          -30,
          -30
      ]
  ],
  "wKingEndgame": [
    [-50, -36, -22, -22, -36, -5],
    [-50, -38, -27, -20, -27, -38, -50],
    [-30, -20, -10, 0, 0, -10, -20, -30],
    [-30, -13, 13, 26, 30, 26, 12, -13, -30],
    [-30, -14, 12, 33, 40, 40, 33, 12, -14, -30],
    [-30, -16, 6, 31, 38, 40, 38, 31, 6, -16, -30],
    [-30, -14, 12, 33, 40, 40, 33, 12, -14, -30],
    [-30, -13, 13, 26, 30, 26, 12, -13, -30],
    [-30, -30, 0, 0, 0, 0, -30, -30],
    [-50, -30, -30, -30, -30, -30, -50],
    [-50, -30, -30, -30, -30, -50]
  ],
  "bKingEndgame": [
    [
        -5,
        -36,
        -22,
        -22,
        -36,
        -50
    ],
    [
        -50,
        -38,
        -27,
        -20,
        -27,
        -38,
        -50
    ],
    [
        -30,
        -20,
        -10,
        0,
        0,
        -10,
        -20,
        -30
    ],
    [
        -30,
        -13,
        12,
        26,
        30,
        26,
        13,
        -13,
        -30
    ],
    [
        -30,
        -14,
        12,
        33,
        40,
        40,
        33,
        12,
        -14,
        -30
    ],
    [
        -30,
        -16,
        6,
        31,
        38,
        40,
        38,
        31,
        6,
        -16,
        -30
    ],
    [
        -30,
        -14,
        12,
        33,
        40,
        40,
        33,
        12,
        -14,
        -30
    ],
    [
        -30,
        -13,
        12,
        26,
        30,
        26,
        13,
        -13,
        -30
    ],
    [
        -30,
        -30,
        0,
        0,
        0,
        0,
        -30,
        -30
    ],
    [
        -50,
        -30,
        -30,
        -30,
        -30,
        -30,
        -50
    ],
    [
        -50,
        -30,
        -30,
        -30,
        -30,
        -50
    ]
],
  "wQueen": [
    [-20, -10, -6, -6, -10, -20],
    [-20, -10, -8, -5, -8, -10, -20],
    [-10, 0, 0, 0, 0, 0, 0, -10],
    [-10, -1, 4, 5, 5, 5, 4, -1, -10],
    [-5, -1, 3, 5, 5, 5, 5, 3, -1, -5],
    [-5, -2, 2, 5, 5, 5, 5, 5, 2, -2, -5],
    [-5, -1, 3, 5, 5, 5, 5, 3, -1, -5],
    [-10, -1, 4, 5, 5, 5, 4, -1, -10],
    [-10, 0, 0, 0, 0, 0, 0, -10],
    [-20, -10, -8, -3, -7, -10, -20],
    [-20, -10, -6, -2, -10.0, -20]
  ],
  "bQueen": [
      [
          -20,
          -10,
          -6,
          -6,
          -10,
          -20
      ],
      [
          -20,
          -10,
          -8,
          -5,
          -8,
          -10,
          -20
      ],
      [
          -10,
          0,
          0,
          0,
          0,
          0,
          0,
          -10
      ],
      [
          -10,
          -1,
          4,
          5,
          5,
          5,
          4,
          -1,
          -10
      ],
      [
          -5,
          -1,
          3,
          5,
          5,
          5,
          5,
          3,
          -1,
          -5
      ],
      [
          -5,
          -2,
          2,
          5,
          5,
          5,
          5,
          5,
          2,
          -2,
          -5
      ],
      [
          -5,
          -1,
          3,
          5,
          5,
          5,
          5,
          3,
          -1,
          -5
      ],
      [
          -10,
          -1,
          4,
          5,
          5,
          5,
          4,
          -1,
          -10
      ],
      [
          -10,
          0,
          0,
          0,
          0,
          0,
          0,
          -10
      ],
      [
          -20,
          -10,
          -7,
          -3,
          -8,
          -10,
          -20
      ],
      [
          -20,
          -10,
          -2,
          -6,
          -10,
          -20
      ]
  ],
  "wRook": [
    [0, 1, -5, -5, -5, 0],
    [0, 3, -5, -5, -5, -5, 0],
    [0, 10, 0, 0, 0, 0, 0, 0],
    [0, 9, 3, 0, 0, 0, 0, 0, 0],
    [0, 8, 4, 0, 0, 0, 0, 0, 1, 5],
    [0, 7, 6, 0, 0, 0, 0, 0, 0, 2, 5],
    [0, 8, 4, 0, 0, 0, 0, 0, 1, 5],
    [0, 9, 3, 0, 0, 0, 0, 0, 0],
    [0, 10, 0, 0, 0, 0, 0, 0],
    [0, 3, -5, -5, -5, -5, 0],
    [0, 1, -5, -5, -5, 0]
  ],
  "bRook": [
      [
          0,
          -5,
          -5,
          -5,
          1,
          0
      ],
      [
          0,
          -5,
          -5,
          -5,
          -5,
          3,
          0
      ],
      [
          0,
          0,
          0,
          0,
          0,
          0,
          10,
          0
      ],
      [
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          9,
          0
      ],
      [
          5,
          1,
          0,
          0,
          0,
          0,
          0,
          4,
          8,
          0
      ],
      [
          5,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          6,
          7,
          0
      ],
      [
          5,
          1,
          0,
          0,
          0,
          0,
          0,
          4,
          8,
          0
      ],
      [
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          9,
          0
      ],
      [
          0,
          0,
          0,
          0,
          0,
          0,
          10,
          0
      ],
      [
          0,
          -5,
          -5,
          -5,
          -5,
          3,
          0
      ],
      [
          0,
          -5,
          -5,
          -5,
          1,
          0
      ]
  ],
  "wBishop": [
    [-20, -10, -10, -10, -10, -20], 
    [-20, -10, -10, -10, -10, -10, -20],
    [-10, 0, 0, 5, 0, 10, 5, -10],
    [-10, -1, 4, 5, 8, 10, 8, -1, -10],
    [-10, -2, 6, 10, 10, 10, 10, 6, -2, -10],
    [-10, -3, 4, 10, 10, 10, 10, 10, 4, -3, -10],
    [-10, -2, 6, 10, 10, 10, 10, 6, -2, -10], 
    [-10, -1, 4, 5,8, 10, 8, -1, -10],
    [-10, 0, 0, 5, 0, 10, 5, -10],
    [-20, -10, -10, -10, -10, -10, -20],
    [-20, -10, -10, -10, -10, -20]
  ],
  "bBishop": [
      [
          -20,
          -10,
          -10,
          -10,
          -10,
          -20
      ],
      [
          -20,
          -10,
          -10,
          -10,
          -10,
          -10,
          -20
      ],
      [
          -10,
          5,
          10,
          0,
          5,
          0,
          0,
          -10
      ],
      [
          -10,
          -1,
          8,
          10,
          8,
          5,
          4,
          -1,
          -10
      ],
      [
          -10,
          -2,
          6,
          10,
          10,
          10,
          10,
          6,
          -2,
          -10
      ],
      [
          -10,
          -3,
          4,
          10,
          10,
          10,
          10,
          10,
          4,
          -3,
          -10
      ],
      [
          -10,
          -2,
          6,
          10,
          10,
          10,
          10,
          6,
          -2,
          -10
      ],
      [
          -10,
          -1,
          8,
          10,
          8,
          5,
          4,
          -1,
          -10
      ],
      [
          -10,
          5,
          10,
          0,
          5,
          0,
          0,
          -10
      ],
      [
          -20,
          -10,
          -10,
          -10,
          -10,
          -10,
          -20
      ],
      [
          -20,
          -10,
          -10,
          -10,
          -10,
          -20
      ]
  ],
  "wKnight": [
    [-50, -36, -30, -30, -36, -50],
    [-50, -38, -30, -30, -30, -38, -50],
    [-40, -20, 0, 5, 0, 5, -20, -40],
    [-30, -4, 8, 13, 15, 13, 8, -4, -30],
    [-30, -7, 8, 17, 20, 20, 17, 11, -3, -30],
    [-30, -9, 6, 16, 19, 20, 19, 16, 9, -6, -30],
    [-30, 7, 8, 17, 20, 20, 17, 11, -3, -30],
    [-30, -4, 8, 13, 15, 13, 8, -4, -30],
    [-40, -20, 0, 5, 0, 5, -20, -40],
    [-50, -38, -30, -30, -30, -38, -50],
    [-50, -36, -30, -30, -36, -50]
  ], 
  "bKnight": [
      [
          -50,
          -36,
          -30,
          -30,
          -36,
          -50
      ],
      [
          -50,
          -38,
          -30,
          -30,
          -30,
          -38,
          -50
      ],
      [
          -40,
          -20,
          5,
          0,
          5,
          0,
          -20,
          -40
      ],
      [
          -30,
          -4,
          8,
          13,
          15,
          13,
          8,
          -4,
          -30
      ],
      [
          -30,
          -3,
          11,
          17,
          20,
          20,
          17,
          8,
          -7,
          -30
      ],
      [
          -30,
          -6,
          9,
          16,
          19,
          20,
          19,
          16,
          6,
          -9,
          -30
      ],
      [
          -30,
          -3,
          11,
          17,
          20,
          20,
          17,
          8,
          7,
          -30
      ],
      [
          -30,
          -4,
          8,
          13,
          15,
          13,
          8,
          -4,
          -30
      ],
      [
          -40,
          -20,
          5,
          0,
          5,
          0,
          -20,
          -40
      ],
      [
          -50,
          -38,
          -30,
          -30,
          -30,
          -38,
          -50
      ],
      [
          -50,
          -36,
          -30,
          -30,
          -36,
          -50
      ]
  ]
}

export default positionIncentives;
