const GameInstructions = () => (
  <>
    <span className="space">
        <span> <span> 💡 </span> <i> <b>!see F10</b> - see allowed moves for your piece. </i> </span>
    </span>
    <span  className="space">
        <span> <span> 🕹️ </span><i> <b>!vote G0 K4</b> - vote for chat's next move. </i> </span>
    </span>
  </>
);

export default GameInstructions;
