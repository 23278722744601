const pieceMap = {
  "bPawn": '♟︎',
  "bKnight":'♞',
  "bBishop":'♝',
  "bKing": '♚',
  "bQueen": '♛',
  "bRook":'♜',
  "wPawn":'♟︎',  // ♙
  "wKnight":'♞', // ♘
  "wBishop": '♝', // ♗
  "wKing": '♚', // ♔
  "wQueen": '♛', // ♕
  "wRook": '♜' // ♖
}; // i used color: white on black emoji pieces for the whites, so they would stand out more.

export default pieceMap;
