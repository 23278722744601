const pieceValues = {
  "Pawn": 100,
  "Knight": 320,
  "Bishop": 330,
  "Rook": 500,
  "Queen": 900,
  "King": 20000
}; // just so you know i have tweaked these significantly since first translating

export default pieceValues;
